import './style.scss';
import React, { Component } from 'react';
import { Form } from 'antd';
import InputSelect from '../shared/inputSelect';
import { getSubDomain } from '../../shared/helper';

class SuperAccountComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allowedAccountsDetails: [],
    };
  }

  componentDidMount() {
    const { allowedAccounts } = this.props;
    let accounts = [];
    allowedAccounts.map(data =>
      accounts.push({ key: data, value: data.toUpperCase() }),
    );
    this.setState({ allowedAccountsDetails: accounts });
    if (!(localStorage.getItem(`${getSubDomain()}Account`))) {
      localStorage.setItem(`${getSubDomain()}Account`, accounts[0]?.key)
    }
  }

  onAccountChange = value => {
    localStorage.setItem(`${getSubDomain()}Account`, value);
    window.location.reload();
  };

  render() {
    const { form, allowedAccounts } = this.props,
      { allowedAccountsDetails } = this.state;
    return (
      <div className={'account-change form-customize'}>
        <InputSelect
          initialValue={
            localStorage.getItem(`${getSubDomain()}Account`)
              ? localStorage.getItem(`${getSubDomain()}Account`)
              : allowedAccounts[0]
          }
          fieldName={'natsAccount'}
          form={form}
          data={allowedAccountsDetails}
          placeholder={'Select account'}
          onChange={this.onAccountChange}
        />
      </div>
    );
  }
}

export default Form.create()(SuperAccountComponent);
